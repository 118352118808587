import getApiStorageUrl from "../../utilities/get-api-storage-url";
import getUserRole from "../../utilities/get-user-role";

export default function normalizeUserResponse(response) {
  try {
    const { data = {} } = response;

    if (!data || !data.status || (!!data.status && data.status !== "success")) {
      throw new Error("Incorrect response format");
    }

    if (!data.user) {
      throw new Error("Missing user");
    }

    let user = { ...data.user };
    const userRole = getUserRole(user.roles);

    if (!userRole) {
      throw new Error("Unauthorized");
    }

    user.role = userRole.name;
    user.capabilities = userRole.capabilities || [];
    user.hasPickedBenefits = data.hasPickedBenefits;
    user.hasTraining = data.hasTraining;

    if (!!user?.agency?.logo) {
      user.agency.logo = getApiStorageUrl(user.agency.logo);
    }

    if (!!data?.currentYear) {
      user.currentYear = data.currentYear;
    }

    const { agency = {} } = user || {};

    if (
      data.hasOwnProperty("shoppingRequired") &&
      !!agency &&
      !!agency.level &&
      ["level_2", "level_3"].includes(agency.level)
    ) {
      user.shoppingRequired = data.shoppingRequired;
    } else {
      user.shoppingRequired = false;
    }

    if (
      data.hasOwnProperty("bonusShoppingRequired") &&
      !!agency &&
      !!agency.level &&
      ["level_2", "level_3"].includes(agency.level)
    ) {
      user.bonusShoppingRequired = data.bonusShoppingRequired;
    } else {
      user.bonusShoppingRequired = false;
    }

    if (!!agency?.scorecardData && typeof agency.scorecardData === "string") {
      agency.scorecardData = JSON.parse(agency.scorecardData);
    }

    let message = data.message || "Success!";
    console.log(user);
    return ["success", user, message];
  } catch (err) {
    return ["error", null, err.message || "Unexpected response"];
  }
}
